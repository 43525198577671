.invoice_main_page_container {
  position: relative;
  // width: 1200px;
  width: 100%;
  margin: 0 auto;
  overflow: auto;
  border: 1px solid #000;
}

.invoice_main_page_image {
  width: 1200px;
  height: 100%;
  object-fit: cover;
  // border: 2px solid #8a8a8a;
}

.invoice_main_page_inputs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 1200px;
  height: 100%;
  z-index: 2;
}

// HEADER INPUTS
.invoice_main_page_header_inputs {
  margin-top: 2.35%;
}

.invoice_checkbox_container {
  margin-top: -1px;
  margin-left: 982px;
  width: 134.5px;
  display: flex;
  justify-content: space-between;
}

.invoice_checkbox_input {
  width: 28px;
  height: 28px;
}

.preparedBy_date_time_container {
  margin-top: 13px;
  display: flex;
  width: 100%;
}

.customerName_customerNo_container {
  margin-top: 12px;
  display: flex;
  width: 100%;
}

.mobile_email_container {
  margin-top: 8px;
  display: flex;
  width: 100%;
  position: relative;
}

.puchased_places_container {
  margin-top: -3px;
  margin-left: 2px;
  display: flex;
  width: 100%;
  position: relative;
}

.transparent_input_itemCode {
  border: 0 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.transparent_input_itemCode:focus {
  border: 0 !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.transparent_input {
  border: 0 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}
.transparent_input:focus {
  border: 0 !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

// footer INPUTS
.footer_container {
  margin-top: 0.85rem;
  width: 100%;
  position: relative;
}

@media print {
  .contentToPrint {
    transform: scale(0.5);
    transform-origin: top left;
  }

  @page {
    size: A4; /* Set a fixed page size (e.g., letter, A4) */
  }
}

// // payment summary

.paymentSummary {
  margin-top: 5.5rem;
  margin-left: -1rem;
}