.input_with_btn {
  padding: 0 !important;
}
.input_with_btn > div{
  border: none !important
}
.dropzone_area {
  margin-top: 0.5rem !important;
  min-height: 123px !important;

  h4 {
    font-size: 1rem;
  }
}

.dz_message_area {
  padding: 0.3rem !important;
};

